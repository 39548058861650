import { Analitica, AmplitudePlugin } from '@stone-payments/analitica'

const isProduction = ['production', 'staging'].includes(process.env.NODE_ENV)

class AmplitudeDashPlugin extends AmplitudePlugin {
  name = 'AMPLITUDE'

  sendEvent (event) {
    const { label, metadata, ...rest } = event

    if (this.instance) {
      this.instance.logEvent(label, { ...metadata, ...rest })
    }
  }
}

/**
 * @typedef {import('@stone-payments/analitica').Analitica} Analitica
 * @description This is the instance of the analitica
 * @description If dryRun is true, the events will not be shown in the console, but they will be sent to the amplitude
 * @description If dryRun is false your adblock should be disabled
 */
const analiticaInstance = new Analitica(
  {
    app: { version: process.env.NODE_ENV },
    user: {
      merchant_id: undefined,
      account_id: undefined,
      user_email: undefined
    }
  },
  [
    new AmplitudeDashPlugin(process.env.VUE_APP_AMPLITUDE_KEY, {
      defaultTracking: false
    })
  ],
  { dryRun: !isProduction }
)

class Singleton {
  constructor () {
    if (!Singleton.instance) {
      Singleton.instance = analiticaInstance
    }
  }

  getInstance () {
    return Singleton.instance;
  }
}

export default new Singleton().getInstance();
