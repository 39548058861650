<template>
  <main class="el-core _fixed">
    <app-core-header
      @userLoggedOut="logout"
      @appMenuClicked="toggleAppMenu"
      @drawerMenuClicked="toggleDrawerMenu"
      :drawerOpen="drawerMenuOpen"
      :isOpen="appMenuOpen"
    ></app-core-header>
    <section class="el-core__wrapper">
      <div class="el-main">
        <div
          class="el-main__navigation"
          :class="{ _open: appMenuOpen }"
        >
          <app-sidemenu
            @appMenuClicked="toggleAppMenu"
            :user="user"
            :isOpen="appMenuOpen"
          />
        </div>
        <div class="el-main__content">
          <div class="el-section">
            <nps
              v-if="showNps"
              @close="closeNps"
            />
            <greeting
              v-if="showGreeting"
              @close="closeGreeting"
            />
            <transition
              :name="transitionName"
              mode="out-in"
            >
              <router-view />
            </transition>
          </div>
          <transition
            name="fade"
            mode="out-in"
          >
            <emd-footer />
          </transition>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import EmdFooter from '@/components/emerald/organisms/EmdFooter.vue'
import AppSidemenu from '@/components/project/ui/menu/AppSidemenu.vue'
import Greeting from '@/components/project/globals/Greeting.vue'
import Nps from '@/components/project/globals/Nps.vue'
import AppCoreHeader from '@/components/project/ui/header/AppCoreHeader.vue'

export default {
  name: 'Motherboard',
  components: {
    AppCoreHeader,
    EmdFooter,
    AppSidemenu,
    Greeting,
    Nps
  },
  data () {
    return {
      appMenuOpen: false,
      drawerMenuOpen: false,
      transitionName: 'slide-right',
      greetingClosed: false
    }
  },
  computed: {
    user () {
      return {
        ...this.$store.getters.userProfile,
        role: this.$store.getters.userType.value
      }
    },
    showGreeting () {
      return this.user && this.$store.getters.showGreeting
    },
    isNewUser () {
      return this.user && this.$store.getters.isNewUser
    },
    showNps () {
      return this.$store.getters.showNps
    }
  },
  watch: {
    $route (to, from) {
      const toDepth = to.path.split('/').length
      const fromDepth = from.path.split('/').length
      this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
      // closes appMenu on route change
      if (to !== from && this.appMenuOpen) this.appMenuOpen = false
    }
  },
  methods: {
    toggleAppMenu () {
      this.appMenuOpen = !this.appMenuOpen
      if (this.appMenuOpen) this.drawerMenuOpen = false
    },
    toggleDrawerMenu () {
      this.drawerMenuOpen = !this.drawerMenuOpen
      if (this.drawerMenuOpen) this.appMenuOpen = false
    },
    async logout () {
      this.$store.dispatch('TOGGLE_GLOBAL_LOADING', true)
      this.$store.dispatch('DELETE_AUTHORIZATION', true)
    },
    closeGreeting () {
      this.$store.dispatch('UPDATE_GREETING', false)
    },
    closeNps () {
      this.$store.dispatch('TOGGLE_NPS')
    },
    closeModal () {
      this.$store.dispatch('UPDATE_MODAL_HEADER', false)
    }
  },
  beforeCreate () {
    // const noMustDisplay = {
    //   employee: this.$store.getters?.isEmployee,
    //   forSupportType: this.$store.getters?.merchantSummary?.support_type === 'special',
    //   forWhiteLabel: this.$store.getters?.merchantSummary?.is_white_label
    // }
  }
}
</script>

<style>
.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition-duration: 500ms;
  transition-property: opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  transform: translateX(calc(2em - 6px));
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  transform: translateX(calc(-2em - 6px));
}
</style>
