import Cookies from 'js-cookie'
import { ProfileTSInstance, IDCoreInstance } from '@/store'
import { catchError } from '@/support/errorLogger.js'
import { setCookieCompany, updateCookieCompany } from '@/support/cookieCompany'
import { identify } from '@fullstory/browser'

import { version } from '@/../package.json'

export const state = () => ({
  profile: undefined,
  type: { key: '', value: '' },
  merchantsCount: null,
  showGreeting: false,
  isNewUser: false
})

const getters = {
  profile (state) {
    return state.profile
  },
  userProfile (state) {
    if (state.profile) {
      return {
        name: `${state.profile.user.first_name} ${state.profile.user.last_name}`,
        email: state.profile.user.email,
        initials: ProfileTSInstance.support.getInitials(
          state.profile.user.first_name,
          state.profile.user.last_name
        )
      }
    } else {
      return state.profile
    }
  },
  isEmployee (state) {
    return state?.profile?.user?.is_employee
  },
  userType (state) {
    if (state?.profile?.user?.is_employee) {
      return state.profile.user.employee_type
    } else if (state.profile) {
      return state.profile.merchant.type
    }
    return state.type
  },
  userHasMultipleMerchant (state) {
    if (state.profile.user) {
      return state.profile.user.is_employee
        ? true
        : state.merchantsCount > 1
    } else {
      return false
    }
  },
  merchantsCount (state) {
    return state.merchantsCount
  },
  merchant (state) {
    if (state.profile) {
      return {
        id: state.profile.merchant.id,
        type: state.profile.merchant.type
      }
    } else {
      return {}
    }
  },
  merchantId (state) {
    return state.profile && state.profile.merchant && state.profile.merchant.id
  },
  showGreeting (state) {
    return state.showGreeting
  },
  isNewUser (state) {
    return state.isNewUser
  }
}

const mutations = {
  setShowGreeting (state, showGreeting) {
    state.showGreeting = showGreeting
  },
  setProfile (state, profile) {
    state.profile = profile
  },
  setIsNewUser (state, isNewUser) {
    state.isNewUser = isNewUser
  },
  setUserProfile (state, profile) {
    state.profile = profile
  },
  setType (state, type) {
    state.type = type
  },
  setMerchantsCount (state, merchantsCount) {
    state.merchantsCount = merchantsCount
  }
}

const actions = {
  GET_AUTHORIZATION ({ dispatch, commit, getters }, params = {}) {
    return ProfileTSInstance.authorization
      .get(params, 'company', process.env.VUE_APP_PROFILE_URL)
      .then(response => {
        commit('setProfile', response)

        const userVars = {
          id: response.user.id,
          displayName: `${response.user.first_name} ${response.user.last_name}`,
          email: response.user.email,
          merchantId_str: response.merchant ? response.merchant.id : null,
          merchantName_str: response.merchant ? response.merchant.name : null,
          userType_str: response.merchant ? response.merchant.type.key : null,
          appVersion_str: version
        }

        if (window.newrelic) {
          window.newrelic
            .interaction()
            .setAttribute('id', userVars.id)
            .setAttribute('username', userVars.displayName)
            .setAttribute('email', userVars.email)
            .setAttribute('merchant_id', userVars.merchantId_str)
        }

        if (['production'].includes(process.env.VUE_APP_CORE_ENV))
          identify(userVars.id, userVars)

        return response
      })
      .then(response => {
        commit('setMerchantsCount', response.user.merchants_count)
        if (response.merchant) {
          const merchant = {
            id: response.merchant.id,
            name: response.merchant.name
          }
          commit('setType', response.merchant.type)
          dispatch('SET_MERCHANT_ACTIVE', merchant)
          if (
            !response?.user?.is_root &&
            !response?.user?.is_employee &&
            (getters.componentIsEnabled('global', 'onboarding') ||
              getters.componentIsEnabled('global', 'nps'))
          ) {
            dispatch('GET_ONBOARDING')
            dispatch('GET_NPS')
          }
          return merchant
        } else {
          return { id: null, name: null }
        }
      })
      .catch(err => catchError(err, { commit, dispatch }))
  },
  GET_ONBOARDING (context) {
    const envAcronym = ProfileTSInstance.envAcronym
    let cookieCompany = Cookies.get(`${envAcronym}mp_company`)

    if (cookieCompany) {
      Promise.resolve(atob(cookieCompany))
        .then(JSON.parse)
        .then(resCookieParse => {
          cookieCompany = resCookieParse
          const onboarding = cookieCompany?.onboarding
          const showGreeting = onboarding?.show_greeting
          const isNewUser = onboarding?.is_new_user
          if (onboarding && Object.entries(cookieCompany.onboarding).length) {
            context.commit('setShowGreeting', showGreeting)
            context.commit('setIsNewUser', isNewUser || showGreeting)
          } else {
            return ProfileTSInstance.onboarding
              .get('company')
              .then(res => {
                if (res && Object.entries(res).length) {
                  const resShowGreeting = res?.show_greeting
                  const resIsNewUser = res?.is_new_user

                  context.commit('setShowGreeting', resShowGreeting)
                  context.commit(
                    'setIsNewUser',
                    resIsNewUser || resShowGreeting
                  )
                } else {
                  context.commit('setShowGreeting', true)
                  context.commit('setIsNewUser', true)
                }
                setCookieCompany({ onboarding: { ...res } })
                return Promise.resolve(res)
              })
              .catch(err => catchError(err, context))
          }
          return Promise.resolve(cookieCompany.onboarding)
        })
    } else {
      return ProfileTSInstance.onboarding
        .get('company')
        .then(res => {
          if (res && Object.entries(res).length) {
            const showGreeting = res?.show_greeting
            const isNewUser = res?.is_new_user

            context.commit('setShowGreeting', showGreeting)
            context.commit('setIsNewUser', isNewUser || showGreeting)
          } else {
            context.commit('setShowGreeting', true)
            context.commit('setIsNewUser', true)
          }
          setCookieCompany({ onboarding: { ...res } })
          return Promise.resolve(res)
        })
        .catch(err => catchError(err, context))
    }
  },
  UPDATE_GREETING (context, showGreeting) {
    context.commit('setShowGreeting', showGreeting)
    updateCookieCompany('onboarding')
    return ProfileTSInstance.onboarding.update('company', {
      show_greeting: context.state.showGreeting,
      is_new_user: context.state.isNewUser
    })
  },
  DELETE_AUTHORIZATION (context, logout = false) {
    context.dispatch('TOGGLE_GLOBAL_LOADING', true)

    IDCoreInstance.auth.connect
      .revocationToken()
      .catch(err => catchError(err, context))
      .finally(() => {
        ProfileTSInstance.authorization.deleteCookies('company', logout)
        window.location.assign(process.env.VUE_APP_DEFAULT_REDIRECT)
        return Promise.resolve()
      })
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
