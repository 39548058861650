var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "eu-display_none",
      staticStyle: { position: "absolute" },
      attrs: { id: `tooltip-${_vm.id}` },
    },
    [
      _c(
        "div",
        { staticClass: "eu-display_flex eu-justify_space-between eu-mb_2" },
        [
          _vm.title
            ? _c("h3", { staticClass: "emd-text _color_primary" }, [
                _vm._v(" " + _vm._s(_vm.title) + " "),
              ])
            : _vm._e(),
          _vm._m(0),
        ]
      ),
      _vm.description
        ? _c("p", { staticClass: "eu-mt_1" }, [
            _vm._v(" " + _vm._s(_vm.description) + " "),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "emd-link _color_subtle",
        attrs: { href: "javascript:void(0)", id: "close-tooltip" },
      },
      [_c("i", { staticClass: "uil uil-times" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }