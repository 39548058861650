var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "el-section el-centered eu-align-items_center" },
    [_c("emd-pagarme-loader", { attrs: { color: "secondary" } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }